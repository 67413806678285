import React from 'react'
import { Container,Row} from 'react-bootstrap'
import './ppbody.css'


const PPbody = () => {
  return (
    <section className='pp-section mt-lg-5 pt-lg-5 pt-3 mt-3'>
        {/* privacy policy section start */}
        <Container className='mb-5 pb-5'>
            <Row>
                <div className='pp-section-para' >

                    <p>PubClub is an invite-only community created for next-gen publishers and content creators. We are a peer-powered network where industry leaders and key decision-makers across news, media, entertainment, OTT, and gaming cultivate a balanced view of opportunities and challenges, helping pioneering publishers and content creators assess and adopt the latest practices in the market. We have curated this community of key leaders and members in an attempt to strengthen the significance of regional nuances in the global ad tech space.
                    </p>

                    <p>PubClub technologies are not downloaded to a user's desktop.</p>
                    <p>PubClub technologies do not upload personally identifiable information from a user's desktop.</p>
                    <p>PubClub technologies do not collect or store any personally identifiable user information.</p>

                    <p>PubClub, Inc. ("PubClub" or "we") respects the privacy concerns of the users of its website, www.andbeyond.media (the "Website"). PubClub thus provides this privacy policy to explain what information is gathered during a visit to the Website and through our services and how such information may be used.</p>

                    <h3>Use of Information</h3>
                    <p>
                    As a general policy no personally identifiable information, such as your name, address, or e-mail address, is automatically collected from your visit to the Website.
                    </p>

                    <p className='bflw'>Specific uses of information on this Website may include:</p>

                    <h3>PubClub’s Apply To Join Form:</h3>
                    <p>The Website contains a "Apply To Join" form for publishers and content creators to request additional information about the membership process. The form requires web publishers and content creators to give contact information (such as name and email address). PubClub uses this information to contact publishers and content creators about the services on the Website in which they have expressed interest. Publishers and content creators have the option to provide information (such as website address and vertical sector) to PubClub. PubClub encourages them to submit this information so we can provide them with a more customized reply to their inquiry.</p>


                    <h3>Testimonials:</h3>

                    <p>If your name appears in a testimonial on this Website, you should be aware that any personally identifiable information displayed there can be read, collected, or used by other users of this Website, and could be used to send you unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these testimonials.</p>

                    <h4>Service-Related Announcements:</h4>
                        
                        <p>PubClub will send publishers and content creators club-related announcements when it is
                        necessary to do so. The other e-mails we send out are in response to feedback, contact and
                        sign-up form submissions, and to enhance the value of the services we provide. Generally,
                        publishers and content creators may not opt out of these communications, which are not
                        promotional in nature. If a publisher or content creator does not wish to receive these
                        communications, they have the option to deactivate their account according to the terms of their
                        Web Services Agreement.</p>
                    
                    <h4>Customer Service:</h4>
                    
                        <p>Based upon the provided personally identifiable information, PubClub will send publishers and
                        content creators a welcoming email to verify their usernames and password and to set up their
                        accounts. PubClub will also communicate with them in response to their inquiries, provide the
                        services they request, and manage their account. PubClub will communicate with publishers
                        and content creators by email or telephone, in accordance with their wishes.<br />
                        All this personally identifiable information may be associated with the usage information and
                        technical information described above.</p>


                    <h3>Legal Disclaimer:</h3>
                    
                    <p>PubClub may disclose user information in special cases when we have reason to believe that disclosing this information is necessary to identify, contact, or bring legal action against someone who may be causing injury to or interference (either intentionally or unintentionally) with PubClub's rights or property, other users of the Website, or anyone else that could be harmed by such activities. PubClub may also disclose member information when necessary to comply with a court order, legal process, or other legal proceeding.</p>
                    
                    <h3>Children's Privacy:</h3>
                    
                    <p>PubClub is very sensitive to the issue of children's privacy. Our Website and club are neither developed for, nor directed at children, and we do not knowingly collect personal information about children under 13. If you believe your child has provided PubClub with personally identifiable data, or registered at the Website and you would like to have the data removed, please contact us at <a href="admin@andbeyond.media">admin@andbeyond.media</a></p>

                
                    <h3>Choice/Opt-Out:</h3>
                    
                    <p>The Website may provide you the opportunity to opt-in to receive communications from us at the point where we request information about you. You always have the option of removing your name from any e-mail list in order to discontinue any such future communications. In order to ensure immediate removal from any list, please follow the specific instructions set forth within the communications you receive from PubClub which you no longer wish to receive. If you are unsuccessful in completing the instructions specified in any such communication, please e-mail us at <a href="mailto:admin@andbeyond.media">admin@andbeyond.media</a>, including a copy of the undesired email attached to the request, and state you wish to be removed from the mailing list.</p>
                    
                    <h3>Use of Cookies</h3>

                    <p>Cookies are pieces of information that a website transfers to an individual's computer hard drive for record-keeping purposes. Cookies make using our Website and technologies easier by, among other things, saving your passwords locally and preferences for you. These cookies are restricted for use only on our Website or websites which our advertising products are enabled on, and and do not transfer any personal information to any other party. Most browsers are initially set up to accept cookies. You can, however, reset your browser to refuse all cookies or indicate when a cookie is being sent. Please consult the technical information relevant to your browser for instructions. If you choose to disable your cookies setting or refuse to accept a cookie, some parts of the Website may not function properly or may be considerably slower.</p>       

                    <p>Contact Information for Questions, Comments, and Complaints: If you have any questions, comments or complaints about www.pubclub or about this privacy statement, please contact:
                        Via mail: <br />
                        3205, X3 Jumeriah Bay Tower, Dubai<br />
                        Via email: finance@andbeyond.media<br />
                        Information provided by you via general e-mail inquiries to PubClub such as your e-mail address is used only to respond to your queries in the ordinary course of business and is never shared with third parties.
                    </p>

                    <p>
                    Google Analytics. We use a tool called "Google Analytics" to collect information about the use of this Website. The Google Analytics features we have implemented are based on Display Advertising (e.g., Remarketing, Google Display Network Impression Reporting, the DoubleClick Campaign Manager Integration, or Google Analytics Demographics and Interest Reporting). We will use your user data from Google's Interest-based advertising or 3rd-party audience data (such as age, gender and interests) within Google Analytics to target interest based advertising to visitors of our Website. We do not combine the information collected through the use of Google Analytics with personally identifiable information unless you have robust notice of, and your prior affirmative (i.e. opt-in) consent to, that merger. Users can opt-out of Google Analytics for Display Advertising and customize Google Display Network ads using the Ads Settings <a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a> or by opting out via <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.      
                    </p>

                    <h3>Security:</h3>
                    <p>Security for all personally identifiable information is extremely important to us. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, PubClub cannot ensure or warrant the security of any information you transmit via the Internet. By transmitting any such information to PubClub, you accept that you do so at your own risk.<br /> 
                        Transfer of Data Outside Your Territory. PubClub may store and process information in various locations throughout the globe. If you are a resident of a jurisdiction where the transfer of your personal information to another jurisdiction requires your consent, then you hereby provide us with your express consent to such transfer of your data</p>

                    <h3>Your Acceptance of These Terms:</h3>
                    <p>By using the Website, you accept the policies and restrictions set forth in this online privacy policy. If you do not agree to this policy, please do not use the Website. If we decide to change our privacy policy, we will post those changes to this privacy policy, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this privacy policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our homepage</p>
                </div>
            </Row>
        </Container>
         {/* privacy policy section ends */}
    </section>
  )
}

export default PPbody
