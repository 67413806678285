import React, {useEffect, useState} from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

//import axios from 'axios';
import Singleheader from '../component/Singleheader'
import Singlebody from '../component/Singlebody'
import { useParams } from 'react-router-dom';
import Menu from '../component/Menu';
import Footer from '../component/Footer';

gsap.registerPlugin(ScrollTrigger);

const Single = ({setBlank}) => {

  const [post, setPost] = useState([]);
  const [relatedPost, setRelatedPost] = useState([]);
  const [content, setContent] = useState("");

  let { id } = useParams();  

    useEffect(() => {

        // fatch single post data 
        fetch('https://blogs.pubclub.io/wp-json/insights/v1/post?id='+id+'&&post_status=publish', { method: "GET"})
        .then(response => response.json())
        .then(data => {
            setPost(data);
            setContent(data['post_content'])

          setTimeout(() => {
              ScrollTrigger.refresh();
          }, 2000);    
  
        });

        
        // fatch related post data
        fetch('https://blogs.pubclub.io/wp-json/insights/related-post/post?id='+id+'&&post_status=publish', { method: "GET"})
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setRelatedPost(data)

            setTimeout(() => {
              ScrollTrigger.refresh();
          }, 2000);    
  
      
        });

        ScrollTrigger.refresh();

      }, [id]);

  return (
    <>
    <Menu /> 
    <Singleheader data={post} />
    <Singlebody setBlank={setBlank} data={post} id={id} content={content} relatedPost={relatedPost} />
    <Footer setBlank={setBlank} class="single" referance="footer.single" />  

    </>
    )
}

export default Single