import React from 'react'
import Menu from '../component/Menu'
import Headerpp from '../component/Headerpp'
import PPbody from '../component/PPbody'
import Footer from '../component/Footer'

const Privacypolicy = ({setBlank}) => {
  return (
    <>
        <Menu />
        <Headerpp />
        <PPbody />
        <Footer setBlank={setBlank}  class="privacy" referance="footer.privacy" />  
        
    </>
      
  )
}

export default Privacypolicy
