import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';

import * as Yup from "yup";
import { Formik, Form, useFormik } from "formik";

import './postfrom.css';
const initialValues = {
  name: "",
  company: "",
  designation: "",
  email: ""
}


const postFormSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please enter your Name").matches(/^[aA-zZ'.,/-\s]+$/, "Only alphabets are allowed for this field "),
  designation: Yup.string().min(2).max(25).required("Please enter your Designation").matches(/^[aA-zZ'.,/-\s]+$/, "Only alphabets are allowed for this field "),
  company: Yup.string().min(3).max(25).required("Please enter your Company").matches(/^[aA-zZ'.,/-\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/, "Email must be a valid email "),
});

const PostFrom = (props) => {

    // function downloadPDF(url, filename) {
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.target= "_blank";
    //     a.download = filename;
    //     document.body.appendChild(a);
    //     a.rel="noreferrer";
    //     a.click();
    //     document.body.removeChild(a);
    // }

   
  const [res, setRes] = useState();
  const [hide, setHide] = useState(false);


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: postFormSchema,
      onSubmit: async (values, action) => {

       // const formId = 'b72f315'; // Replace with your Contact Form 7 form ID
       // const url = `https://blogs.pubclub.io/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;
        
        // on form submission
          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("company", values.company);
          formData.append("designation", values.designation);
          formData.append("email", values.email);
          formData.append("title",  props.title);

          //downloadPDF('https://blogs.pubclub.io/wp-content/uploads/2025/01/Publisher_Playbook_2025.pdf', 'Publisher_Playbook_2025.pdf');
       
                  setHide(true);
                  fetch('https://blogs.pubclub.io/wp-json/mail/sendpostmail', {
                    method: 'POST',
                    body: formData
                    })
        
                  .then(response => response.json())
                  .then(data => {
                  // enter you logic when the fetch is successful
                    setHide(false);
                    console.log(data);
                    // downloadPDF('https://blogs.pubclub.io/wp-content/uploads/2025/01/Publisher_Playbook_2025.pdf', 'Publisher_Playbook_2025.pdf');
                   // document.getElementById('downloadpdf').click();
                    setRes('Thank you for the submission.');
                    setTimeout(()=>{
                      setRes("");
                    }, 2000);
                    
                  })

                .catch(error => {
                console.log(error)
                })
                document.getElementById('downloadpdf').click();
               


            // try {
            //     const response = await fetch(url, {
            //         method: 'POST',
            //         body: formData
            //     });
        
            //     if (response) {
            //         console.log(response);
            //         const data = await response.json();
            //         console.log('Form submitted successfully:', data);
            //         setHide(false);
            //         console.log(data)
            //         setRes(data);
            //         setTimeout(()=>{
            //         setRes("");
            //         }, 2000);
            //     } else {
            //        console.error('Error submitting form:', response.statusText);
            //     }
            // } catch (error) {
            //     console.error('Error submitting form:', error);
            // }

            action.resetForm();

      },
  
    });


   
  //   console.log(
  //   errors
  // );




  return (
    <>
    {/* Contact form section start */}
      <section id="postForm" className='postform-section' >
        <Container fluid className='px-5p'>
        <a id='downloadpdf' style={{opacity:0,visibility:'hidden'}} target='_blank' rel="noreferrer"  href="https://tinyurl.com/publisherplaybook2025">download</a>
                 
          <Row className=''>
            <Col className='col-md-6 col-lg-5 col-xl-4 mx-auto'>
              <Formik>
                {() => {
                  return (

                    // contact form start
                    <Form className="bg-white" onSubmit={handleSubmit}>
                      <div className="form-group my-4">
                        <input type="text" name="name" placeholder="NAME" className="form-control" value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                      </div>
                      <div className='errormsg'> {errors.name && touched.name ? (
                        <p className="form-error">{errors.name}</p>
                      ) : null}</div>
                      <div className="form-group my-4">
                        <input type="text" name="company" placeholder="COMPANY NAME" className="form-control" value={values.company}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                      </div>
                      <div className='errormsg'> {errors.company && touched.company ? (
                        <p className="form-error">{errors.company}</p>
                      ) : null}</div>
                      <div className="form-group my-4">
                        <input type="text" name="designation" placeholder="DESIGNATION" className="form-control" value={values.designation}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                      </div>
                      <div className='errormsg'> {errors.designation && touched.designation ? (
                        <p className="form-error">{errors.designation}</p>
                      ) : null}</div>
                      
                      <div className="form-group my-4">
                        <input type="email" name="email" placeholder="EMAIL" className="form-control inputpadding" value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}/>
                      </div>
                      <div className='errormsg'> {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}</div>
                      
                      
                    

                      <div className="form-group my-4 text-center flex items-center justify-between">
                        
                      {(hide) ? (
                        <button 
                        disabled
                        style={{opacity:"0.5"}}
                        className="applynow-btn"
                        type="submit"
                      >
                        Submit
                      </button>
                      
                      ) :  (
                          <button 
                          className="applynow-btn"
                          type="submit"
                        >
                          Submit
                        </button>                            
                        )
                      }
                      
                        <p className="form-success mt-5">{res}</p>
                      </div>
                    </Form>
                   // contact form ends

                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    {/* Contact form section start */}

    </>
  )
}

export default PostFrom
