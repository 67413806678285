import React from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import brandlogo from "./../images/Brand Logo.webp";
import brandlogomb from "./../images/brandlogo-mb.webp";
//import Form from 'react-bootstrap/Form';
import { NavLink ,Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useParams } from 'react-router-dom';
import "./menu.css"

gsap.registerPlugin(ScrollTrigger);

export const Menu = () => {

    let expand = 'md';
    let homeurl = "/";

    let { id } = useParams();  

  return (
    <>

<Container fluid className='main-menu px-md-5 px-sm-0'>
    {/* Nav menu start */}
    <Navbar key={expand} bg="transparent"  expand={expand} className="navbar-dark mb-3 mobilebg">
            <Navbar.Brand href={homeurl}><img className='desktop' src={id !== "" ? brandlogo : brandlogo } alt="Logo" /><img className='mobile' src={id !== "" ? brandlogomb : brandlogomb } alt="Logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                        <NavLink to={homeurl} className="mobile-view-img"><img src={id !== "" ? brandlogo : brandlogo } alt='Brand Logo' /></NavLink>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="justify-content-end gap-md-5 flex-grow-1 pe-3 mobilebiew">
                        {/* <NavLink to="memberbenefits">MEMBER BENEFITS</NavLink> */}
                        <NavLink to="/about" className="borderbottom" >ABOUT</NavLink>
                        <NavLink to="/member-benefits" className="borderbottom">MEMBER BENEFITS</NavLink>
                        <NavLink to="/members" className='borderbottom' >MEMBERS</NavLink>
                        
                        {/* temp comm */}
                        {/* <NavDropdown 
                            className='desktop communitymargin'
                            title="COMMUNITY"
                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                            show={show}
                            onMouseEnter={showDropdown} 
                            onMouseLeave={hideDropdown}  
                        >
                            <NavDropdown.Item> <NavLink to="/advisorycouncil" onClick={scrollToTop} >ADVISORY COUNCIL</NavLink></NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item ><NavLink to="/members" onClick={scrollToTop} >MEMBERS</NavLink></NavDropdown.Item>
                        </NavDropdown> */}

                        {/* <NavLink className='mobile borderbottom' to="/advisorycouncil">ADVISORY COUNCIL</NavLink> */}
                        
                        {/* <NavLink className='mobile borderbottom' to="/members">MEMBERS</NavLink> */}
                        {/* temp comm */} 
                        
                        <NavLink to="/insights" >INSIGHTS</NavLink>
                        <NavLink className='desktop' to="/apply-to-join">APPLY TO JOIN</NavLink>
                    </Nav>

                    <div className='btn-box bordertop'>
                      <NavLink to="/apply-to-join" onClick={()=>{setTimeout(() => {ScrollTrigger.refresh();}, 100);    }}><button className="applynow-btn btnmb mobile ">APPLY TO JOIN</button></NavLink> 
                    </div>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
                {/* Nav menu ends */}
        </Navbar>
    </Container>

    <Outlet />
    </>
  )
}

export default Menu
