import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/Gotham-Bold.otf';
import './App.css';

// import ReactDOM from 'react-dom';  
//import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import {Routes , Route } from "react-router-dom";
import React, {useState} from 'react'

// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

//import { createBrowserHistory } from 'history';
// import Menu from './component/Menu';
// import Header from './component/Header';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Members from './pages/Members';


// import Header from './component/Header';
// import Vision from './component/Vision';
// import HoverTab from './component/HoverTab';
// import Meet from './component/Meet';
// import Menu from './component/Menu';
// import Footer from './component/Footer';
import MemberBenefits from './pages/MemberBenefits';
import AdvisoryCouncil from './pages/AdvisoryCouncil';
import About from './pages/About';
import Single from './pages/Single';
import Insights from './pages/Insights';
import Termscondition from './pages/Termscondition';
import Privacypolicy from './pages/Privacypolicy';

// gsap.registerPlugin(ScrollTrigger);

function App() {

  const [blank, setBlank] = useState(false);

  return (


    <div className="App">
    
    {/* <BrowserRouter basename={"/staging/pubclub"}> */}
    {/* <BrowserRouter history={history} basename={"/staging/pubclub"} >  */}
      {/* <Menu /> */}  
      
      {(blank) ? <div className='blank'> </div> : ""}
      <Routes>
        {/* <Route path="/" element={<Menu />}> */}
          {/* <Route index element={<Home />} /> */}
          <Route path='/' element={ <Home setBlank={setBlank}  /> } exact />
          <Route path="about" element={ <About setBlank={setBlank} />} exact  />
          <Route path="members" element={ <Members setBlank={setBlank} />} exact  />
          <Route path="member-benefits" element={ <MemberBenefits setBlank={setBlank}  />} exact  />
          <Route path="insights" element={ <Insights setBlank={setBlank}  />} exact  />
          <Route path="single/:id/" element={ <Single setBlank={setBlank} />}  exact  /> 
          <Route path="advisorycouncil" element={ <AdvisoryCouncil setBlank={setBlank} />} exact  />
          <Route path="apply-to-join" element={ <Contact setBlank={setBlank} />} exact  />
          <Route path="terms-and-conditions" element={ <Termscondition setBlank={setBlank} />} exact  />
          <Route path="privacy-policy" element={ <Privacypolicy setBlank={setBlank} />} exact  />
          
          
          {/* <Route path="*" element={<>Members</>} /> */}
        {/* </Route> */}
        
      </Routes>
      {/* <Footer />      */}
 
    {/* </BrowserRouter> */}

      {/* <Router>
        <Routes>
           <Route index element={    
            <header className='header-home '  > 
            <Container fluid className='hero px-5'>
              <Menu />
              <Header />
            </Container>
            </header>   

          } />
            <Route exact path='/staging/pubclub/' element={< Home />}></Route>
           <Route exact path='/staging/pubclub/members' element={<Members />}></Route>
           <Route exact path='/staging/pubclub/apply-to-join' element={<Contact />}></Route>
           <Route exact path='/staging/pubclub/contact' element={<h1> Contact </h1>}></Route>
        </Routes>
       </Router> */}

    </div>
   
  );
}

export default App;
