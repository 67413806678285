import React from 'react'
import Menu from '../component/Menu'
import Headertandc from '../component/Headertandc'
import Tandcbody from '../component/Tandcbody'
import Footer from '../component/Footer'

const Termscondition = ({setBlank}) => {
  return (
    <>
      <Menu />
      <Headertandc />
      <Tandcbody />
      <Footer setBlank={setBlank} class="termscon" referance="footer.termscon" />  
    </>
  )
}

export default Termscondition
