import React, { useEffect ,useState} from 'react'
import { Col, Row ,Container} from 'react-bootstrap'
import down from '../images/down.webp';

import arrowlink from '../images/arrow-link-hbg.webp'
import nextp from '../images/next-page.webp'
import previousp from '../images/previous-page.webp'
import { NavLink } from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import ReactPaginate from "react-paginate";
import './insightsbody.css'

gsap.registerPlugin(ScrollTrigger);

export const Insightbody = ({setBlank}) => {

    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
          ScrollTrigger.refresh();
          window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 800);
    }

      const scrollTop = () => {
        setTimeout(() => {
            window.scrollTo(0, window.innerHeight / 2)
        }, 100);
      }

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(8);
  
    
    const postPerPage = 8;
    
    // const pagesVisited = page * usersPerPage;
    const pageCount = Math.ceil(total / postPerPage);
    const changePage = ({ selected }) => {
        // setPageNumber(selected);
        scrollTop();
        setPage(selected+1);
    };


    useEffect(() => {
        // fatch blogs data using api
        fetch('https://blogs.pubclub.io/wp-json/insights/v1/posts?page='+page+'&&post_status=publish', { method: "GET"})
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setPosts(data);
            setTotal(data[0].total);
        });

        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 1500);    

    }, [page]);

    return (
    <>
    {/* insights section start */}
    <div className="blog-section"> 
        <div className="blog-list">

        {posts.map((post) => {

         return (
                <div key={post.ID} className="single-blog">
                    <div className="d-flex gap-x-30">
                    {/* <NavLink to={`/single/${post.ID}/${url}`} > */}
                    <NavLink onClick={scrollToTop} to={`/single/${post.ID}`} >
                    <div className="blog-content"><img src={post.thumbnail} alt='' />
                        <h2 className='mt-3' dangerouslySetInnerHTML={{__html:post.post_title}} ></h2> 
                        <p className='mt-2'>by {post.auther_name}   -    {post.post_date}</p>
                    </div>
                    </NavLink>
                    <div className="overlay-box"><img src={arrowlink} alt='' />
                        <svg className="green-bg" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.28654 0.5L38.8965 32.4416C30.1543 41.2472 15.9904 41.2395 7.26353 32.4184C-1.46334 23.5973 -1.45568 9.3056 7.28654 0.5Z" fill="#B8E819"/>
                        </svg>
                    </div>
                    </div>
                </div>                     
            );

        })}    
  
        </div>

        <Row className='pagination mt-5'>
           <Col className='col-md-6 ms-auto text-right'>
                <ReactPaginate
                previousLabel={<img src={previousp} alt='' />}
                nextLabel={<img src={nextp} alt='' />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                />
           </Col>     
        </Row>


        {/* want to know more section mobile starts */}
        <Container className='mobileview-footer mb-4 pt-4'>
        <Row>
          <Col className='mx-auto text-center mt-5'>
            <div className='footer-heading'>
              <h3>WANT  T<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span></h3>
              <h3>KN<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span>W M<span><img src={down} alt='' /></span>RE?</h3>
            </div>

            <NavLink to='/apply-to-join' onClick={scrollToTop}>  <button className="contact-btn mt-50">CONTACT US</button></NavLink>
          </Col>
        </Row>
      </Container>
        {/* want to know more section mobile ends */}

    </div>
        {/* insights section ends */}

    </>
  )
}

export default Insightbody