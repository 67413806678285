import React from 'react'
import Menu from '../component/Menu';
import Aboutheader from '../component/Aboutheader'
import Aboutsection from '../component/Aboutsection'
import Footer from '../component/Footer'

const About = ({setBlank}) => {
  return (
    <> 
      <Menu /> 
      <Aboutheader/>
      <Aboutsection setBlank={setBlank} /> 
      {/* <Footer />  */}
      <Footer setBlank={setBlank} class="about" referance="footer.about" />  

    </>
  )
}

export default About