import React from 'react'
import './advisorysection.css'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../images/Maskgroupimg.webp'
import img2 from '../images/timmymobileimg.webp'
import down from '../images/down.webp';
import { NavLink } from "react-router-dom";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Advisorysection = ({setBlank}) => {

    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
            ScrollTrigger.refresh();
            window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 700);
      }


    return (
        <>
        {/* advisory council section start */}
            <section className='advisory-section'>
                {/* desktop section start  */}
                <Container className='mb-5 pb-5 advisorydesktop-view'>
                    <Row>
                        <Col className='col-md-3'>
                            <div>
                                <img src={img1} alt=" " />
                                <h4 className='advsection-heading mt-5'>Timmy Bankole</h4>
                                <p className='advsection-subheading'>Director, Advertising Business Operations at South China </p>
                                <p className='advsection-subheading mt-para'>Marketing Post</p>
                            </div>
                        </Col>

                        <Col className='col-md-9 border-bottom'>
                            <p className='advsection-para'>Timmy Bankole, Director, Advertising Business Operations at South China Marketing Post is a veteran marketer with over 12 years of experience across the digital landscape. His experience can be scaled over varied expertise including an understanding of supply mix, publisher performance, and strategic revenue growth.
                            </p>
                        </Col>
                    </Row>
                </Container>
                {/* desktop section ends  */}
                {/* mobile section start  */}
                <Container className='advisorymobile-view avisorymobile-view-margin'>
                    <Row>
                        <Col className='col-md-1'>
                            <div>
                                <img src={img2} alt=" " />
                            </div>
                        </Col>
                        <Col className='col-md-11 margin-div'>
                            <h4 className='advsection-heading border-bottom pb-2'>Timmy Bankole</h4>
                            <p className='advsection-subheading'>Director, Advertising Business<br /> Operations at South China Marketing Post</p>
                        </Col>
                    </Row >
                    <Row>
                        <Col>

                            <p className='advsection-para'>Timmy Bankole, Director, Advertising Business Operations at South China Marketing Post is a veteran marketer with over 12 years of experience across the digital landscape. His experience can be scaled over varied expertise including an understanding of supply mix, publisher performance, and strategic revenue growth.
                            </p>

                        </Col>
                    </Row>
                </Container >
                {/* mobile section ends  */}
                {/* advisory council want to know more section start */}
                <Container className='mobileview-footer mt-3 pt-3 mb-0'>
                    <Row>
                        <Col className='mx-auto text-center mt-5'>
                            <div className='footer-heading'>
                                <h3>WANT  T<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span></h3>
                                <h3>KN<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }} /></span>W M<span><img src={down} alt='' /></span>RE?</h3>
                            </div>

                            <NavLink to='/apply-to-join' onClick={scrollToTop} ><button className="contact-btn mt-5">CONTACT US</button></NavLink>
                        </Col>
                    </Row>
                </Container>
             {/* advisory council want to know more section ends */}

            </section >
            {/* advisory council section Ends */}

        </>
    )
}

export default Advisorysection